

















































































import './scss/BaseGroup.scss';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SlideUpDown from 'vue-slide-up-down';

@Component({
    name: 'BaseGroup',

    components: {
        BaseListItemShell: () => import('@components/BaseListItemShell'),
        BaseCardShell: () => import('@components/BaseCardShell'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        SlideUpDown,
    },
})
export default class BaseGroup extends Vue {
    @Prop({}) title!: string;
    @Prop({
        default: 'list',
        validator(value: string) {
            if (['list', 'cards'].includes(value)) return true;
            throw new Error('BaseGroup validator error: property "type" should be a "list" or "cards"');
        },
    }) type!: 'list' | 'cards';
    @Prop({ default: true }) selectable!: boolean;
    @Prop({}) items!: any[];
    @Prop({}) flags!: any[];
    @Prop({ default: false }) foundSelectedOnArray!: boolean;
    @Prop({}) selectedItems!: string[];
    @Prop({}) deletedItems!: any[];
    @Prop({}) usedIn!: string;
    @Prop({}) disabledItems!: Set<string>;
    @Prop({ default: false }) disabledAll!: boolean;
    @Prop({ default: false }) changeGroups!: boolean;
    @Prop({ default: false }) showCountItems!: boolean;
    @Prop({ default: () => [] }) filters!: any[];
    @Prop({ default: () => [] }) filtersApply!: boolean;

    isItemsShown = false;
    appearanceDuration = 300;

    @Watch('changeGroups')
    watchChangeGroups() {
        if (this.usedIn === 'tasks') {
            this.isItemsShown = false;

            if (this.title !== 'Непрочитанные') {
                this.isItemsShown = this.itemListNotEmpty;
            }
        }
    }

    @Watch('filters')
    watchFilters() {
        this.isItemsShown = this.itemListNotEmpty;
    }

    mounted() {
        if (this.filtersApply && this.usedIn === 'about-project-members') {
            this.isItemsShown = this.itemListNotEmpty;
        }
    }

    get itemsListClassNameModifier() {
        return `base-group__items--${this.type}`;
    }

    get itemListNotEmpty(): boolean {
        return this.items.length > 0;
    }

    toggleItemsShown() {
        this.isItemsShown = !this.isItemsShown;
    }

    toggleItemSelection(item: any) {
        item.selected = !item.selected;
        this.$emit('selection-changed', item);
    }
}
